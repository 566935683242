import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import ReleaseForm from './ReleaseForm';
import SceneGrid from './SceneGrid';
import ReleaseSearchBar from './ReleaseSearchbar';
import { handlePublishWithNotification } from './services/handlePublishWithNotification';

const EditRelease = ({ releaseId, onCancel }) => {
  const [name, setName] = useState('');
  const [beschreibung, setBeschreibung] = useState('');
  const [status, setStatus] = useState('Geplant');
  const [releasedAt, setReleasedAt] = useState('');
  const [scenes, setScenes] = useState([]);
  const [filteredScenes, setFilteredScenes] = useState([]);
  const [selectedScenes, setSelectedScenes] = useState([]);
  const [gameData, setGameData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchReleaseData();
    fetchScenes();
  }, [releaseId]);

  const fetchReleaseData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      const releaseData = response.data.data;
      setName(releaseData.attributes.name);
      setBeschreibung(releaseData.attributes.beschreibung);
      setStatus(releaseData.attributes.Status);
      setReleasedAt(releaseData.attributes.released_at || '');

      const initialSelectedScenes = releaseData.attributes.videoszenes?.data.map(
        (scene) => scene.id
      ) || [];
      setSelectedScenes(initialSelectedScenes);
    } catch (error) {
      console.error('Fehler beim Abrufen der Release-Daten:', error);
    }
  };

  const fetchScenes = async () => {
    try {
      let allScenes = [];
      let page = 1;
      let moreData = true;

      while (moreData) {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/videoszenes?populate=*&pagination[page]=${page}&pagination[pageSize]=10`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        );

        const scenesData = response.data.data;
        allScenes = [...allScenes, ...scenesData];

        if (scenesData.length < 10) {
          moreData = false;
        } else {
          page += 1;
        }
      }

      setScenes(allScenes);
      setFilteredScenes(allScenes);

      const gameDataTemp = {};
      for (const scene of allScenes) {
        if (scene.attributes.spiel?.data?.id) {
          const gameId = scene.attributes.spiel.data.id;
          const gameResponse = await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/spiels/${gameId}?populate=*`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            }
          );

          gameDataTemp[gameId] = gameResponse.data.data.attributes;
        }
      }
      setGameData(gameDataTemp);
    } catch (error) {
      console.error('Fehler beim Abrufen der Szenen oder Spieldaten:', error);
    }
  };

  const handleNameChange = (newName) => {
    setName(newName);
    saveRelease({ name: newName, beschreibung, status, released_at: releasedAt, videoszenes: selectedScenes });
  };

  const handleBeschreibungChange = (newBeschreibung) => {
    setBeschreibung(newBeschreibung);
    saveRelease({ name, beschreibung: newBeschreibung, status, released_at: releasedAt, videoszenes: selectedScenes });
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    saveRelease({ name, beschreibung, status: newStatus, released_at: releasedAt, videoszenes: selectedScenes });
  };

  const handleReleasedAtChange = (newDate) => {
    setReleasedAt(newDate);
    saveRelease({ name, beschreibung, status, released_at: newDate, videoszenes: selectedScenes });
  };

  const saveRelease = async (updatedData) => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}`,
        { data: updatedData },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      console.log('Release erfolgreich aktualisiert');
    } catch (error) {
      console.error('Fehler beim Speichern des Releases:', error);
    }
  };

  const handleSceneToggle = async (sceneId) => {
    const updatedScenes = selectedScenes.includes(sceneId)
      ? selectedScenes.filter((id) => id !== sceneId)
      : [...selectedScenes, sceneId];

    setSelectedScenes(updatedScenes);
    saveRelease({ name, beschreibung, status, released_at: releasedAt, videoszenes: updatedScenes });
  };

  const handlePublishWithNotificationClick = async () => {
    await handlePublishWithNotification({
      name,
      beschreibung,
      status,
      releasedAt,
      selectedScenes,
      releaseId,
      setSuccessMessage,
      fetchReleaseData,
      fetchScenes,
    });
  };

  const handlePublish = async () => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}`,
        {
          data: { 
            name, 
            beschreibung, 
            Status: 'Veröffentlicht', 
            released_at: releasedAt, 
            videoszenes: selectedScenes 
          },
        },
        { 
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('authToken')}` 
          } 
        }
      );
  
      const updateScenesPromises = selectedScenes.map(async (sceneId) => {
        await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/videoszenes/${sceneId}`,
          { 
            data: { arbeitsstatus: 'published' } 
          },
          { 
            headers: { 
              Authorization: `Bearer ${localStorage.getItem('authToken')}` 
            } 
          }
        );
      });
  
      await Promise.all(updateScenesPromises);
      setSuccessMessage('Release und Szenen erfolgreich veröffentlicht.');
      fetchReleaseData();
      fetchScenes();
    } catch (error) {
      console.error('Fehler beim Veröffentlichen des Releases und der Szenen:', error);
    }
  };
  


  const handleSearch = (searchParams) => {
    if (searchParams.type === 'scene') {
      const filtered = scenes.filter((scene) => {
        const matchTitle = scene.attributes.titel ? scene.attributes.titel.toLowerCase().includes(searchParams.searchText.toLowerCase()) : false;
        const matchDescription = scene.attributes.beschreibung ? scene.attributes.beschreibung.toLowerCase().includes(searchParams.searchText.toLowerCase()) : false;
        const matchThema = !searchParams.thema || scene.attributes.thema.some(t => t.name === searchParams.thema);
        const matchBewertung = !searchParams.bewertung || scene.attributes.Bewertung?.some(b => b.Bewertung === parseInt(searchParams.bewertung, 10));
        return (matchTitle || matchDescription) && matchThema && matchBewertung;
      });

      setFilteredScenes(filtered);
    } else if (searchParams.type === 'game') {
      const filtered = scenes.filter((scene) => {
        const matchTeam = !searchParams.team || scene.attributes.spiel?.data?.attributes?.heimverein?.data?.attributes?.name.includes(searchParams.team);
        const matchLiga = !searchParams.liga || scene.attributes.spiel?.data?.attributes?.liga?.data?.attributes?.Name.includes(searchParams.liga);
        const matchSaison = !searchParams.saison || scene.attributes.spiel?.data?.attributes?.saison?.includes(searchParams.saison);
        return matchTeam && matchLiga && matchSaison;
      });

      setFilteredScenes(filtered);
    }
  };

  const availableScenes = filteredScenes.filter((scene) => !selectedScenes.includes(scene.id));
  const selectedSceneDetails = filteredScenes.filter((scene) => selectedScenes.includes(scene.id));

  return (
    <div className="w-full p-6">
      <h2 className="text-2xl font-bold mb-4">Release bearbeiten: {name}</h2>

      {successMessage && (
        <div className="alert alert-success shadow-lg mb-4">
          <div>
            <span>{successMessage}</span>
          </div>
        </div>
      )}

      <ReleaseForm
        name={name}
        beschreibung={beschreibung}
        status={status}
        onNameChange={handleNameChange}
        onBeschreibungChange={handleBeschreibungChange}
        onStatusChange={handleStatusChange}
      />

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Veröffentlichungsdatum:</label>
        <input
          type="date"
          value={releasedAt}
          onChange={(e) => handleReleasedAtChange(e.target.value)}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <h3 className="text-xl font-bold mb-4">Szenen, die Teil des Releases sind</h3>
      <SceneGrid
        scenes={selectedSceneDetails}
        selectedScenes={selectedScenes}
        onSceneToggle={handleSceneToggle}
        gameData={gameData}
      />

      <h3 className="text-xl font-bold mb-4 mt-4">Szenen zum Release hinzufügen</h3>
      <ReleaseSearchBar onSearch={handleSearch} />
      <SceneGrid
        scenes={availableScenes}
        selectedScenes={selectedScenes}
        onSceneToggle={handleSceneToggle}
        gameData={gameData}
      />

      <div className="flex justify-end mt-6">
        <button className="btn btn-secondary mr-2" onClick={onCancel}>
          Zurück
        </button>
        <button className="btn btn-primary mr-2" onClick={handlePublishWithNotificationClick}>
          Veröffentlichen mit Benachrichtigung
        </button>
        <button className="btn btn-primary" onClick={handlePublish}>
          Veröffentlichen
        </button>
      </div>
    </div>
  );
};

export default EditRelease;
