// Funktion für das Tag
export const SourceTag = ({ source }) => {
    const colors = {
      DFB: 'bg-green-500 text-white', // Farbe für DFB
      UEFA: 'bg-blue-500 text-white', // Farbe für UEFA
    };
  
    return (
      <span
        className={`px-2 py-1 rounded-full text-sm font-semibold ${
          colors[source] || 'bg-gray-300 text-black'
        }`}
      >
        {source}
      </span>
    );
  };

