import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import RelatedScenesSidebar from './RelatedScenesSidebar';
import SimilarScenesSidebar from './SimilarScenesSidebar';
import SceneDetails from './SceneDetails';
import { saveOrUpdateUserScene } from './services/sceneService';
import { SourceTag } from './SourceTag';

const VideoszeneAnsicht = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [similarThemes, setSimilarThemes] = useState([]);
  const [similarSceneIds, setSimilarSceneIds] = useState([]);
  const [scene, setScene] = useState(null);
  const [signedVideoUrl, setSignedVideoUrl] = useState(null);
  const [releaseId, setReleaseId] = useState(location.state?.releaseId || null);
  const videoRef = useRef(null);
  const [hasSentHalfView, setHasSentHalfView] = useState(false);
  const [viewStartTime, setViewStartTime] = useState(null);
  const [bewertungen, setBewertungen] = useState([]);
  const [viewId, setViewId] = useState(null);
  const [srBewertungen, setSrBewertungen] = useState([]);
  const [sra1Bewertungen, setSra1Bewertungen] = useState([]);
  const [sra2Bewertungen, setSra2Bewertungen] = useState([]);

  const decodeToken = (token) => {
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Fehler beim Decodieren des Tokens:', error);
      return null;
    }
  };


  const authToken = localStorage.getItem('authToken');
  const decodedToken = decodeToken(authToken);
  const userId = decodedToken ? decodedToken.id : null;

  const handleVideoPlay = async () => {
    console.log('Video wird abgespielt, Versuche View zu speichern...');
    const startTime = new Date();
    setViewStartTime(startTime);

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/videoszene-views`, {
        data: {
          viewedAt: startTime.toISOString(),
          videoszene: id,
          view_duration: 0,
        },
      }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log(response.data.data);
      const newViewId = response.data.data.id;
      setViewId(newViewId);
      console.log('View erfolgreich gespeichert mit ID:', newViewId);
    } catch (error) {
      console.error('Fehler beim Speichern der Video-View:', error);
      console.error('Fehlermeldung:', error.response?.data || error.message);
    }
  };

  const handleTimeUpdate = async () => {
    const videoElement = videoRef.current;
    const currentTime = videoElement.currentTime;
    const duration = videoElement.duration;
    if (!hasSentHalfView && currentTime >= duration / 2) {
      setHasSentHalfView(true);
      await saveOrUpdateUserScene(userId, id);
    }
  };


  const handleVideoEnd = async () => {
    if (viewStartTime && viewId) {
      const endTime = new Date();
      const viewDuration = Math.round((endTime - new Date(viewStartTime)) / 1000);
      const dataToUpdate = {
        view_duration: viewDuration,
      };

      try {
        await axiosInstance.put(`${process.env.REACT_APP_API_URL}/videoszene-views/${viewId}`, {
          data: dataToUpdate,
        }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('View Dauer erfolgreich aktualisiert.');
      } catch (error) {
        console.error('Fehler beim Aktualisieren der View-Dauer:', error);
        console.error('Fehlermeldung:', error.response?.data || error.message);
      }
    }
  };

  useEffect(() => {
    const fetchScenesFromRelease = async () => {
      if (!releaseId) return;

      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}?populate=videoszenes.thumbnail`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const releaseScenes = response.data.data.attributes.videoszenes?.data || [];
        const sceneIds = releaseScenes.map(scene => scene.id);
        setSimilarSceneIds(sceneIds);
        console.log("Similar Scene IDs from Release:", sceneIds); // Logging

      } catch (error) {
        console.error('Fehler beim Laden der Szenen aus dem Release:', error);
      }
    };

    fetchScenesFromRelease();
  }, [id, releaseId, authToken]);

// Hinzufügen eines Loggings zur Überprüfung des releaseId
console.log("Release ID:", releaseId);


useEffect(() => {
  console.log('Abrufen von Videoszene:', id);
  const fetchScene = async () => {
    setSignedVideoUrl(null); // Zurücksetzen für neue URL
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes/${id}?populate=spiel.heimverein,spiel.gastverein,spiel.liga,Video,thumbnail,Bewertung,thema`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const sceneData = response.data.data;
      setScene(sceneData);

      if (sceneData) {
        const videoData = sceneData.attributes.Video?.data;
        if (videoData) {
          const videoKey = videoData.attributes.hash + videoData.attributes.ext;
          const signedVideoRes = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get-signed-video-url/${videoKey}`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          setSignedVideoUrl(signedVideoRes.data.signedUrl);
        }

        const bewertungen = sceneData.attributes.Bewertung || [];
        setSrBewertungen(bewertungen.filter(b => !b.Kategorie.startsWith('SRA')));
        setSra1Bewertungen(bewertungen.filter(b => b.Kategorie.startsWith('SRA1')));
        setSra2Bewertungen(bewertungen.filter(b => b.Kategorie.startsWith('SRA2')));
      }
    } catch (error) {
      console.error('Fehler beim Laden der Szene:', error);
    }
  };

  fetchScene();
}, [id, authToken]);

useEffect(() => {
  const videoElement = videoRef.current;
  if (!videoElement) return;

  videoElement.addEventListener('play', handleVideoPlay);
  videoElement.addEventListener('timeupdate', handleTimeUpdate);
  videoElement.addEventListener('ended', handleVideoEnd);

  return () => {
    videoElement.removeEventListener('play', handleVideoPlay);
    videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    videoElement.removeEventListener('ended', handleVideoEnd);
  };
}, [viewStartTime, viewId, authToken, id, userId]);

  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/aktuelle-szenen');
    }
  };

  const renderRatingRadioButtons = (bewertung) => {
    return (
      <div className="flex flex-col items-start mb-4 ml-2">
        
        <div className="flex gap-2 mt-4">
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <label key={value} className="flex flex-col items-center cursor-not-allowed">
              <input
                type="radio"
                name={`bewertung-${bewertung.id}`}
                value={value}
                checked={bewertung.Bewertung === value}
                disabled
                className={`radio radio-sm ${bewertung.Bewertung === value ? 'radio-primary' : 'radio-secondary'}`}
              />
              <span className="text-xs mt-1">{value}</span> {/* Bewertungswert unter dem Radio-Button */}
            </label>
          ))}
        </div>
      </div>
    );
  };
  
  


  const handleDownloadVideoFromDom = () => {
    if (signedVideoUrl) {
        console.log('Download wird vorbereitet:', signedVideoUrl);

        // Erstelle einen Link und setze das download-Attribut
        const link = document.createElement('a');
        link.href = signedVideoUrl;
        link.setAttribute('download', 'video.mp4');
        link.target = '_blank';  // Erzwinge, dass der Download in einem neuen Tab geöffnet wird
        link.style.display = 'none';

        // Füge den Link zum DOM hinzu und klicke darauf, um den Download zu starten
        document.body.appendChild(link);

        // Verwende setTimeout, um sicherzustellen, dass der Browser das Attribut download erkennt
        setTimeout(() => {
            link.click();
            document.body.removeChild(link);  // Entferne den Link nach dem Klick
        }, 100);

        console.log('Download wurde erfolgreich initiiert.');
    } else {
        console.error('Kein Video-URL vorhanden.');
    }
};
          

  

  if (!scene) {
    return <p className="text-center">Lade Szene...</p>;
  }

  const {
    titel,
    beschreibung,
    spielstrafe,
    persoenlicheStrafe,
    Video,
    download,
    quelle,
  } = scene.attributes;

  return (
    <div key={id} className="w-11/12 mx-auto p-6 grid grid-cols-1 lg:grid-cols-[80%,20%] gap-4">
      <div>
        <button className="btn btn-secondary mb-4" onClick={handleBack}>
          Zurück
        </button>

        <h2 className="text-3xl font-bold mb-4 flex items-center gap-2">
          {titel}
          {(quelle === 'DFB' || quelle === 'UEFA') && <SourceTag source={quelle} />}
        </h2>
        {(quelle === 'DFB' || quelle === 'UEFA') && (
  <p className="text-sm text-gray-700 mt-2 mb-6">
   Szenen aus DFB und UEFA sind nicht zum Download verfügbar und dürfen nicht außerhalb der Plattform verwendet werden.
  </p>
)}

        {signedVideoUrl ? (
          <div className="flex justify-center mb-4">
            <video
              controls
              className="w-9/12 rounded-lg shadow-lg"
              controlsList="nodownload"
              onPlay={handleVideoPlay} 
              onContextMenu={(e) => e.preventDefault()}
              ref={videoRef}
            >
              <source src={signedVideoUrl} type={Video?.data?.attributes?.mime} />
              Dein Browser unterstützt das Video-Tag nicht.
            </video>
          </div>
        ) : (
          <p>Kein Video verfügbar.</p>
        )}

        {download && signedVideoUrl && (
          <button className="btn btn-primary" onClick={handleDownloadVideoFromDom}>
            Video herunterladen
          </button>
        )}

    <SceneDetails
          beschreibung={beschreibung}
          spielstrafe={spielstrafe}
          persoenlicheStrafe={persoenlicheStrafe}
          srBewertungen={srBewertungen}
          sra1Bewertungen={sra1Bewertungen}
          sra2Bewertungen={sra2Bewertungen}
          renderRatingRadioButtons={renderRatingRadioButtons}
        />
      </div>
      {releaseId ? (
    <>
      {console.log('Lade RelatedScenesSidebar mit Release ID:', releaseId)}
      <RelatedScenesSidebar releaseId={releaseId} />
    </>
  ) : (
    <>
      {console.log('Lade SimilarScenesSidebar')}
      <SimilarScenesSidebar themes={similarThemes} similarSceneIds={similarSceneIds} />
    </>
)}

    </div>
  );
};

export default VideoszeneAnsicht;
