import axiosInstance from '../axiosConfig';
import emailjs from 'emailjs-com';

/**
 * Publishes a release with notifications to users.
 * @param {Object} params - Parameters for publishing.
 * @param {string} params.name - The name of the release.
 * @param {string} params.beschreibung - The description of the release.
 * @param {string} params.status - The status of the release.
 * @param {string} params.releasedAt - The release date (ISO format or null).
 * @param {Array} params.selectedScenes - Array of scene IDs to be included in the release.
 * @param {string} params.releaseId - The ID of the release to update.
 * @param {Function} setSuccessMessage - Callback to update success messages in the UI.
 * @param {Function} fetchReleaseData - Callback to refresh release data.
 * @param {Function} fetchScenes - Callback to refresh scenes data.
 */
export const handlePublishWithNotification = async ({
  name,
  beschreibung,
  status,
  releasedAt,
  selectedScenes,
  releaseId,
  setSuccessMessage,
  fetchReleaseData,
  fetchScenes,
}) => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms)); // Helper function for delay

  try {
    const validStatuses = ['Geplant', 'Veröffentlicht'];
    const trimmedStatus = status.trim();

    if (!validStatuses.includes(trimmedStatus)) {
      console.error(`Invalid Status: ${trimmedStatus}. Must be one of: ${validStatuses.join(', ')}`);
      setSuccessMessage('Fehler: Ungültiger Status.');
      return;
    }

    const formattedReleasedAt = releasedAt ? new Date(releasedAt).toISOString().split('T')[0] : null;

    console.log('Publishing Payload:', {
      name,
      beschreibung,
      Status: trimmedStatus,
      released_at: formattedReleasedAt,
      videoszenes: selectedScenes,
    });

    // Publish the release
    await axiosInstance.put(
      `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}`,
      {
        data: {
          name,
          beschreibung,
          Status: trimmedStatus,
          released_at: formattedReleasedAt,
          videoszenes: selectedScenes,
        },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
    );

    console.log('Release erfolgreich veröffentlicht.');

    // Update scenes
    const updateScenesPromises = selectedScenes.map(async (sceneId) => {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/videoszenes/${sceneId}`,
        { data: { arbeitsstatus: 'published' } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
    });

    await Promise.all(updateScenesPromises);
    setSuccessMessage('Release und Szenen erfolgreich veröffentlicht.');

    // Fetch users with notifications enabled
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/users`, {
      params: {
        'filters[notifications][$eq]': true,
        'pagination[pageSize]': 100,
      },
    });

    const users = response?.data || [];
    console.log('Fetched Users:', users);

    if (!users.length) {
      console.warn('Keine Benutzer mit Benachrichtigungen gefunden.');
      setSuccessMessage('Release veröffentlicht, aber keine Benachrichtigungen gesendet.');
      return;
    }

    // Send email notifications sequentially with delay
    for (const user of users) {
      const templateParams = {
        to_name: user.vorname || 'Benutzer',
        to_email: user.email,
        release_name: name,
        release_text: beschreibung,
      };

      try {
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_RELEASE,
          templateParams,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );
        console.log(`E-Mail erfolgreich gesendet an: ${user.email}`);
      } catch (emailError) {
        console.error(`Fehler beim Senden an ${user.email}:`, emailError);
      }

      // Delay of 1 second (1000 ms)
      await delay(5000);
    }

    setSuccessMessage('Release veröffentlicht und Benachrichtigungen erfolgreich gesendet.');

    // Refresh data
    fetchReleaseData();
    fetchScenes();
  } catch (error) {
    console.error('Fehler beim Veröffentlichen oder Versenden der Benachrichtigungen:', error);
  }
};
